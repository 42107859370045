@use "../../../../styles/variables" as *;
@import "../../../../styles/global";

.container {
  display: flex;
  margin: 0 0 0.5em 0;
  width: 100%;
  user-select: none;
}

.avatar {
  height: 2em;
  width: 2em;
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 1/1;
  margin-right: 0.5rem;
}

.content {
  font-size: 0.875rem;
  width: 90%;
}

.comment {
  display: inline;
  word-wrap: break-word;

  span {
    white-space: pre-wrap;
  }
}

.userName {
  font-size: 0.875rem !important;
  font-weight: bold;
  margin: 0 0.5em 0 0;
  word-wrap: break-word;
}

.tag {
  color: $primaryColor;
  word-wrap: break-word;
}

.metaData {
  @extend .text-small;
  color: $gray4Color;
  display: block !important;
  margin: 0.25em 0;

  span:first-child {
    margin: 0 1em 0 0;
  }
}

.showReply {
  display: flex;
  justify-items: flex-start;
  align-items: center;
  margin: 0.5rem 0 0 0;
}

.verticalLine {
  width: 2.5em;
  height: 0;
  margin: 0 0.5em 0 0;
  border: 1px solid $lightGrayColor;
}
