@import '../../../../styles/variables';
@import '../../../../styles/global';


.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $lightGrayColor;
  height: 8%;

  h1 {
    font-size: 0.9375rem;
    margin: 0;
    font-weight: bold;
  }
  div {
    position: absolute;
    left: 0;
    padding: 1em;
  }
}

.container {
  overflow-y: scroll;
  height: 100%;
}

.avatarContainer {
  border-bottom: 1px solid $lightGrayColor;
}

.avatar {
  height: 5rem;
  width: 5rem;
  border-radius: 2.5rem;
  object-fit: cover;
  margin-top: 1rem;
}

.changeAvatarBtn {
  @extend .text-smaller;
  border: none;
  background-color: $whiteColor;
  color: $primaryColor;
  font-weight: bold;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.infoContainer {
  .field {
    @extend .text-medium;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-items: center;
    padding: 0 0 0 1em;
    margin: 0.5em 0;

    .label {
      flex: 2.5;
      padding: 0.75em 0 0.75em 0;
      align-self: start;
    }

    .inputContainer {
      flex: 5;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      padding: 0.5em 0 0.75em 0;
      border-bottom: 1px solid $lightGrayColor;

      input,
      textarea {
        width: 100%;
        padding: 0 1em 0 0;
      }
      input {
        border: 1px solid transparent;
        margin: 0 1em 0 0;
      }

      p {
        margin: 0;
        color: $primaryColor;
        font-weight: bold;
      }
    }
  }

  textarea {
    resize: none;
    height: 12vh !important;
  }
}

.btnContainer {
  padding: 0rem 1rem;
  margin-bottom: 1rem;
  align-self: flex-end;

  .btn {
    color: $whiteColor;
    background-color: $primaryColor;
    padding: 0.5rem 0rem 0.5rem 0rem;
    border-radius: 0.5rem;
    border: none;
    margin-top: 0.25rem;

    &.gray {
      background-color: $gray2Color;
    }
  }

  button:disabled {
    opacity: 0.6;
  }
}
