@use "../../../../styles/global" as *;
@use "../../../../styles/variables" as *;

.share {
  @extend .text-small;
  background-color: $primaryColor;
  color: $whiteColor;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.swiperContainer {
    width: 100%;
    aspect-ratio: 4/5;
}

.imgContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.imgIndex {
  @extend .transparent-background;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0.25rem 0.75rem;
  border-radius: 0.75rem;
}
