@use "../../../../styles/variables" as *;
@use "../../../../styles/global" as *;

.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  overflow-y: scroll;
  background-color: $whiteColor;
  z-index: 9999;

  .searchBar {
    @extend .text-medium;
    color: $gray4Color;
    padding: 1rem;
    border-bottom: 1px solid $lightGrayColor;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    > div {
      flex: 1;
      min-width: 0;
      background-color: $lightGrayColor;
      margin-right: 1rem;
      display: flex;
      align-items: center;
      padding: 0.5rem 0.75rem;
      border-radius: 0.5rem;

      img {
        height: 15px;
        width: 15px;
        object-fit: contain;
        margin-right: 0.5rem;
      }

      input {
        border: none;
        background-color: $lightGrayColor;
        flex: 1;
        min-width: 0;
      }
    }

    button {
      color: $gray4Color;
    }
  }

  .item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.25rem;

    img {
      height: 3.5rem;
      width: 3.5rem;
      border-radius: 50%;
      object-fit: cover;
    }

    .right {
      margin-left: 0.75rem;
      flex: 1;
      min-width: 0;

      > div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .username {
      @extend .text-large;
      font-weight: bold;
    }

    .name {
      @extend .text-medium;
      margin-top: 0.25rem;
    }

    .username,
    .name {
      width: 60vw;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .empty {
    @extend .text-medium;
    text-align: center;
    margin-top: 2rem;
  }
}

@media all and (min-width: 321px) {
  .username,
  .name {
    width: 70vw !important;
  }
}
