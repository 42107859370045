@import "../../../../styles/global";
@import "../../../../styles/variables";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid $lightGrayColor;

  .left {
    display: flex;
    align-items: center;
    min-width: 0;
    flex: 1;
    margin-right: 0.5rem;
  }

  .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .cancel {
    @extend .text-large;
  }
}

.delete {
  @extend .text-medium;
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: $whiteColor;
  color: $primaryColor;
  font-weight: bold;
  text-align: center;
  padding: 0.75rem 0rem;
  filter: drop-shadow(0 -1rem 1rem #505c6229);
}
