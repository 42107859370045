@use "../../../../styles/variables" as *;
@import "../../../../styles/global";

.form {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;

  > p {
    @extend .text-small;
    color: $gray4Color;
  }

  .button {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 5rem;

    button {
      @extend .text-medium;
      background-color: $primaryColor;
      color: $whiteColor;
      padding: 0.5rem;
      border-radius: 0.5rem;

      &:disabled {
        opacity: 0.5;
      }
    }
  }
}
