.container {
  // height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  // min-height: 100vh;
  // min-height: -webkit-fill-available;
}

.screenTitle {
  position: fixed;
  z-index: 10;
  margin: 1rem;
  font-size: 1.4375rem;
  color: white;
  font-weight: bold;
}
