@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap");

@font-face {
  font-family: Yu Gothic;
  src: local("Yu Gothic"), url("./assets/fonts/yugothic.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: Yu Gothic;
  src: local("Yu Gothic Bold"), url("./assets/fonts/yugothib.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: Open Sans;
  src: url("./assets/fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: PlayfairDisplay;
  src: url('./assets/fonts/PlayfairDisplay-Regular.ttf');
}

@font-face {
  font-family: MrEavesSanOT;
  src: url('./assets/fonts/MrEavesSanOT-Bold.ttf');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

