$primaryColor: #D95454;
$primaryDarkColor: #3E3E3E;
$redColor:#FF3B3B;
$whiteColor:#FFFFFF;
$lightGrayColor:#E0E0E0;
$gray1Color:#A3A9AF;
$gray2Color: #929292;
$gray3Color: #777777;
$gray4Color:#707070;
$blackColor:#000000;
$black1Color:#0B0B0B;
$black2Color: #050505;
$greenColor: #2DD633;
$darkBlueColor:#324755;