@import "../../../../styles/global";
@import "../../../../styles/variables";

.share {
  @extend .text-small;
  background-color: $primaryColor;
  color: $whiteColor;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;

  &:disabled {
    opacity: 0.7;
  }
}

.video {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  display: block;
}
