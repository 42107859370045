@import "../../styles/variables";
@import "../../styles/global";

.wrapper {
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background-color: $whiteColor;
    border: 1px solid $primaryColor;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 0.6rem;
      color: $whiteColor;
      content: "\f00c";
      display: none;
    }

    &:checked {
      background-color: $primaryColor;

      &::after {
        display: block;
      }
    }
  }

  label {
    @extend .text-smaller;
    color: $gray1Color;
    flex: 1;
    margin-left: 0.5rem;
  }
}
