@import "../../../../styles/global";
@import "../../../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @extend .text-larger;
  color: $blackColor;
  margin-top: 5rem;
}

.subtitle {
  @extend .text-medium;
  color: $gray4Color;
  margin-top: 1rem;
  text-align: center;
}

.content {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
}
