.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25em 0.5em;
  border-bottom: 1px solid #E0E0E0;
  p {
    margin: 0;
    padding: 0;
    font-size: 0.75rem;
  }
}

.avatar {
  img {
    width: 1.75em;
    height: 1.75em;
    border-radius: 50%;
  }
  margin: 0 0.75em 0 0;
}
