@import "../../../../styles/global";
@import "../../../../styles/variables";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  .left {
    flex: 1;
    min-width: 0;
    background-color: $lightGrayColor;
    padding: 0.25rem 0.75rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0.25rem;

    img {
      height: 15px;
      width: 15px;
      object-fit: contain;
      margin-right: 0.5rem;
    }

    input {
      font-size: 1rem;
      border: none;
      background-color: $lightGrayColor;
      color: $primaryDarkColor;
      flex: 1;
      min-width: 0;
    }
  }

  button {
    @extend .text-medium;
    margin-left: 0.5rem;
    color: $primaryDarkColor;
  }
}
