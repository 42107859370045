@import "../../../../styles/variables";
@import "../../../../styles/global";

.container {
  padding: 1rem;
  color: $gray4Color;

  .date {
    @extend .text-medium;
    font-weight: bold;
  }

  .title {
    @extend .text-medium;
    font-weight: bold;
    margin-top: 1rem;
  }

  .content {
    @extend .text-small;
    margin-top: 1rem;
  }
}
