@use "../../../../styles/global" as *;
@use "../../../../styles/variables" as *;

.container {
  display: flex;
  flex-direction: column;
}

.header {
  @extend .text-medium;
  border-bottom: 1px solid $lightGrayColor;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  @extend .text-medium;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: $lightGrayColor;
  margin-top: 2rem;
}
