@use "../../../../styles/variables" as *;

.swiper {
  height: 100%;
  background-color: white !important;
  z-index: 0 !important;
}

.swiperSlide {
  background-color: white !important;
  display: flex;
}

