@import "../../styles/variables";

.app-bar {
  background-color: $whiteColor;
  border-bottom: 1px solid $lightGrayColor;

  .entama {
    font-family: Playfair Display, serif;
    font-size: 23px;
    color: #000;
  }
}
