@import "../../styles/variables";
@import "../../styles/global";

.component-text-input {
  @extend .text-small;
  background-color: $lightGrayColor;
  color: $primaryDarkColor;
  border-radius: 0.25rem;

  ::placeholder {
    color: $gray4Color;
  }
}
