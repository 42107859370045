@import "../../../../styles/variables";
@import "../../../../styles/global";

.container {
  bottom: 0;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 9999;
  background-color: white;
  padding: 0.5rem;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;

  .avatar {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    object-fit: cover;
  }

  form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin: 0 0 0 0.5rem;
    position: relative;
    height: 50%;

    textarea {
      font-size: 0.8125rem;
      color: $primaryDarkColor;
      width: 100%;
      height: 2.5625rem;
      max-height: 5rem;
      line-height: 1.25rem;
      padding: 0.6rem 2.5rem 0.675rem 0.75rem;
      border-radius: 0.5em;
      resize: none;
      border: none;
      outline: none;
      background-color: #e0e0e0;

      div {
        background-color: black;
      }
    }
  }
}

.sendBtn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 2.5%;
  height: 1.75em;
  width: 1.75em;
  border-radius: 50%;
  background-color: #d95454;

  &:disabled {
    opacity: 0.5;
  }
}

.replyTarget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 0.5em 0.5em 1em;
  p {
    padding: 0 !important;
    margin: 0 !important;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2em;
  }
}
