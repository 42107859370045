@import "../../../../styles/global";
@import "../../../../styles/variables";

.container {
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: $whiteColor;
  padding: 0.5rem;
  width: 100%;

  > img {
    height: 2.25rem;
    width: 2.25rem;
    object-fit: contain;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .right {
    background-color: $lightGrayColor;
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.5rem;

    textarea {
      @extend .text-medium;
      background-color: $lightGrayColor;
      flex: 1;
      padding-left: 0.25rem;
      margin-right: 0.5rem;
      width: 100%;
    }

    .send {
      background-color: $primaryColor;
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 12px;
        width: 12px;
        object-fit: contain;
      }
    }
  }
}
