@use "../../styles/variables" as *;
@import "../../styles/global";

.tag {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  text-decoration: none;

  img {
    width: 9.25pt;
    height: 12.5pt;
    object-fit: contain;
  }

  div {
    @extend .text-small;
    color: $whiteColor;
    margin-left: 0.5rem;
  }
}

.playIcon {
  transform: scale(2) translate3d(0, 0, 0);
  position: absolute;
  z-index: 11;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.7));
  fill: rgba(255, 255, 255, 0.8);
  width: 30px;
  display: block
}

.actionContainer {
  @extend .text-medium;
  font-weight: bold;
  color: $whiteColor;
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userInfo {
  display: flex;
  margin-top: 0.25rem;

  img {
    width: 2.5rem;
    height: 2.5rem;
    object-fit: cover;
    // aspect-ratio: 1/1;
    border-radius: 0.375em;
  }

  .userNames {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
    color: $whiteColor;
    word-break: break-all;

    div:first-child {
      @extend .text-medium;
      font-weight: bold;
    }

    div:last-child {
      @extend .text-smaller;
    }
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: $blackColor;
  height: 100%;
  width: 100%;
  overflow: hidden;

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: #000;
  }

  iframe {
    width: 120% !important;
    height: 170% !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
}

.caption {
  @extend .text-medium;
  color: $whiteColor;
  max-height: 30vh;
  overflow-y: scroll;
  flex: 1;
  margin-top: 0.5rem;
  word-wrap: break-word;

  .readMore {
    color: $gray4Color;
  }
}

.follow {
  @extend .text-medium;
  background-color: $primaryColor;
  color: $whiteColor;
  padding: 0.15rem 0.5rem;
  border-radius: 0.25rem;
  margin-left: 0.5rem;
}

.infoContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 1rem 1rem 1rem;
  background: transparent linear-gradient(180deg,#00000000,#000) 0 0 no-repeat padding-box;
  z-index: 2;
  transform: translate3d(0, 0, 0);

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:first-child {
      align-items: flex-end;
    }

    &:nth-child(2) {
      align-items: center;
    }

    &:nth-child(3) {
      align-items: flex-end;
    }
  }
}

.hidden {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000093;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    @extend .text-medium;
    color: $whiteColor;
    margin-bottom: 0.5rem;
  }

  button {
    @extend .text-small;
    background-color: $primaryColor;
    color: $whiteColor;
    padding: 0.25rem 1rem;
    border-radius: 0.25rem;
  }
}
