@import "../../../../styles/global";
@import "../../../../styles/variables";

.container {
  margin-bottom: 2rem;

  .header {
    @extend .text-medium;
    color: $primaryDarkColor;
    border-bottom: 1px solid $lightGrayColor;
    padding: 0.75rem 0;
    text-align: center;
  }

  .item {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    > div {
      margin-top: 0.5rem;
    }

    input {
      @extend .text-medium;
    }

    .mark {
      @extend .text-small;
      background-color: $primaryColor;
      color: $whiteColor;
      padding: 0.15rem 1rem;
      border-radius: 0.25rem;
    }
  }
}

.error {
  width: 100%;
  color: red;
  text-align: center;
}
