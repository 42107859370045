@use "../../../../styles/variables" as *;
@import "../../../../styles/global";

.container {
  height: 100%;
  overflow-y: scroll;

  ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0;
    padding: 0.5rem 0;
    border-bottom: 1px solid $lightGrayColor;
  }

  li {
    @extend .text-small;
    list-style: none;
    color: $gray4Color;
    padding: 0.25rem 1rem;
    border-radius: 1.25em;
  }
}

.active {
  color: white !important;
  background-color: $primaryColor;
}
