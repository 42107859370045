.container {
  padding: 1em;
}

.title {
  margin-bottom: 1em;
}

.list {
  display: flex;
  flex-wrap: wrap;

  div {
    margin-right: 0.5em;
    margin-bottom: 1.4em;

    a {
      background-color: rgba(45, 45, 45, .07);
      border-radius: 4px;
      color: #2d2d2d;
      padding: 10px 15px;
      text-decoration: none;
      font-size: 0.875rem;
    }
  }

}