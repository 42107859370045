.container {
  width: 100vw;
  height: 100%;
  overflow: hidden;

  img {
    width: 100%;
    position: fixed;
  }

}
.content {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;

  h1, h2 {
    font-family: PlayfairDisplay;
    font-weight: bold;
  }

  h2 {
    font-size: 1.25rem;
  }

  h1 {
    font-size: 2.6875rem;
  }

  h1, h2 {
    transform: translateY(-2.5rem);
  }

  button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    width: 100%;
    color: inherit;

    p {
      font-size: 0.875rem;
      margin: 0;
    }
  }
}