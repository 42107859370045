@use "../../../../styles/variables" as *;
@use "../../../../styles/global" as *;

.button {
  @extend .text-medium;
  color: $gray4Color;
  background-color: $whiteColor;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5em;
  border-bottom: 1px solid $lightGrayColor;
  width: 100%;
  p {
    margin: 0;
  }
}