@import "../../styles/variables";
@import "../../styles/global";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
  padding: 0 1rem 1rem 1rem;

  .subtitle {
    @extend .text-small;
    text-align: center;
    color: $primaryDarkColor;
    margin-bottom: 1rem;
  }

  .item {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;

    &:last-of-type {
      label {
        @extend .text-medium;
        color: $gray4Color;
        margin-bottom: 0.3rem;
      }

      textarea {
        @extend .text-small;
        background-color: $lightGrayColor;
        border-radius: 0.25rem;
        padding: 0.5rem 0.75rem;
        color: $primaryDarkColor;
        resize: none;
      }
    }
  }

  > button {
    @extend .text-medium;
    background-color: $primaryColor;
    color: $whiteColor;
    padding: 0.5rem 5rem;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
  }
}
