@import "../../../../styles/global";
@import "../../../../styles/variables";

.tabButton {
  @extend .text-small;
  background-color: $whiteColor;
  color: $gray2Color;

  border: none;
  padding: 0.2rem 1rem 0.2rem 1rem;
  border-radius: 0.75rem;

  &.active {
    background-color: $primaryColor;
    color: $whiteColor;
  }
}

.imgContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;

  div {
    aspect-ratio: 4/5;
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #e0e0e0;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
  
}

.tagContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;

  div {
    aspect-ratio: 4/5;
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #e0e0e0;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
}

.videoContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;

  div {
    aspect-ratio: 4/5;
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #e0e0e0;
    overflow: hidden;

    img {
      width: 100%;
    }

    video {
      width: 100%;
      display: block;
    }
  }
}
