@import "../../../../styles/variables";
@import "../../../../styles/global";

.container {
  padding: 1rem;

  button {
    @extend .text-small;
    padding: 0.2rem 0.5rem;
    border-radius: 0.25rem;
  }
}

.avatar {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}

.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $primaryDarkColor;
  font-size: 1.375rem;
  font-family: MrEavesSanOT;
  font-weight: bold;

  label {
    font-size: 0.75rem;
    font-weight: bold;
    opacity: 50%;
  }
}

.nameTop {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;

  .name {
    flex: 1;
    min-width: 0;
    @extend .text-large;
    font-weight: bold;
    color: $gray4Color;
    margin-right: 0.5rem;
    overflow-wrap: break-word;

    &.hidden {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.username {
  @extend .text-medium;
  color: $gray4Color;
}

.followBtn {
  background-color: $primaryColor;
  color: $whiteColor;
  margin-right: 0.5rem;

  &.unfollow {
    background-color: $whiteColor;
    color: $primaryColor;
    border: 1px solid $primaryColor;
  }
}

.chatBtn {
  border: 1px solid $lightGrayColor;
  color: $primaryDarkColor;
}

.unblockBtn {
  border: 1px solid $lightGrayColor;
  color: $primaryDarkColor;
  margin-right: 0.5rem;
}

.bio {
  @extend .text-medium;
  color: $primaryDarkColor;
  margin-top: 0.5rem;
}
