@import "../../../../styles/variables";
@import "../../../../styles/global";

.submit {
  @extend .text-medium;
  background-color: $primaryColor;
  color: $whiteColor;
  border-radius: 5px;
  padding: 0.25rem 1.5rem 0.25rem 1.5rem;
  margin-top: 3rem;
  margin-bottom: 1rem;

  &:disabled {
    opacity: 50%;
  }
}

.smallButton {
  @extend .text-smaller;
  color: $gray1Color;
  background-color: $whiteColor;
  margin-top: 1rem;
}

button {
  display: block;
}

.checkBox {
  margin-top: 0.3rem;
  margin-bottom: 1.5rem;
}
