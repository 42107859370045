@import "../../../../styles/global";
@import "../../../../styles/variables";

.container {
  height: 100%;
}

.avatar {
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  object-fit: cover;
  margin-right: 0.5rem;
}

.username {
  @extend .text-medium;
  font-weight: bold;
  color: $blackColor;
  margin-right: 0.5rem;
}

.comment {
  @extend .text-smaller;
  color: $gray4Color;
}

.captionContainer {
  border-bottom: 1px solid $lightGrayColor;
  padding: 1rem 1rem 1rem 1rem;

  img {
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    object-fit: cover;
    margin-right: 0.5rem;
  }

  .username {
    @extend .text-medium;
    font-weight: bold;
    word-break: break-all;
    white-space: pre-wrap;
  }

  .caption {
    @extend .text-medium;
    margin-left: 2.5rem;
    word-break: break-word;
    white-space: pre-wrap;
  }
}

.replyContainer {
  display: flex;

  .avatar {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.75rem;
    object-fit: cover;
  }

  .username {
    @extend .text-small;
    font-weight: bold;
  }
}

.commentList {
  padding: 1rem;
  margin: 0 0 2.5em 0;
}

.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
}
