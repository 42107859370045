@import "../../../../styles/global";
@import "../../../../styles/variables";

.affiliate {
  @extend .text-small;
  background-color: $primaryColor;
  color: $whiteColor;
  padding: 0.25rem 0.5rem;
  margin-right: 1rem;
  border-radius: 0.25rem;
}

.menuIcon {
  height: 20px;
  width: 23.5px;
  object-fit: contain;
}

.bottomSheet {
  > div {
    width: 100%;
    padding: 1rem;
    padding-left: 2rem;
    padding-bottom: 2em;

    &:first-of-type {
      border-bottom: 1px solid $lightGrayColor;
    }
  }

  button {
    @extend .text-medium;
    color: $primaryDarkColor;
  }
}
