@import "../../../../styles/variables";
@import "../../../../styles/global";

.container {
  @extend .text-medium;

  .header {
    font-weight: bold;
    border-bottom: 1px solid $lightGrayColor;
    padding: 0.75rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .clear {
      position: absolute;
      right: 1rem;
      color: $lightGrayColor;
      font-weight: bold;
    }
  }

  .body {
    padding: 1rem;

    > button {
      background-color: $primaryColor;
      color: $whiteColor;
      width: 100%;
      padding: 0.5rem;
      border-radius: 0.5rem;
      margin-top: 0.5rem;
    }

    input[type="checkbox"] {
      display: flex;
      justify-content: center;
      align-items: center;
      appearance: none;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
      background-color: $whiteColor;
      border: 1px solid lightgray;
      cursor: pointer;

      &::after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 0.8rem;
        color: $whiteColor;
        content: "\f00c";
      }

      &:checked {
        background-color: $primaryColor;
        border: 1px solid $primaryColor;
      }
    }
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0rem;
  }
}
