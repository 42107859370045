@import "../../../../styles/global";
@import "../../../../styles/variables";

.container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $lightGrayColor;
  padding: 0.75rem 1rem;
}

.left {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
  margin-right: 0.5rem;
}

.avatarContainer {
  position: relative;
  margin-right: 0.5rem;

  img {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    object-fit: cover;
  }

  > div {
    @extend .text-smaller;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background-color: $primaryColor;
    color: $whiteColor;
    position: absolute;
    top: 0px;
    left: 0px;
    font-weight: bold;
  }
}

.name {
  @extend .text-medium;
  font-weight: bold;
  margin-bottom: 0.25rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.message {
  @extend .text-small;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.time {
  @extend .text-small;
  opacity: 30%;
  align-self: flex-end;
}

.checkbox {
  height: 1.5rem !important;
  width: 1.5rem !important;
  border-radius: 50%;

  &::after {
    font-size: 0.9rem !important;
  }
}
