.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 9999;
}

.container {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: white;
  border-radius: 1.5em 1.5em 0 0;
  border: 1px solid #707070;
}

.content {
  width: 100%;
  max-height: 70vh;
  overflow-y: scroll;
}

.close {
  width: 5em;
  height: 0.5em;
  margin: 0.5em auto 1em auto;
  border-radius: 0.25em;
  background-color: #e0e0e0;
}
