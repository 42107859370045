@import "../../../../styles/variables";
@import "../../../../styles/global";

.container {
  height: 100%;
}

.filter {
  @extend .text-medium;
  color: $gray4Color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  border-bottom: 1px solid $lightGrayColor;
}

.item {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  border-bottom: 1px solid $lightGrayColor;

  .left {
    display: flex;
    flex: 1;

    img {
      height: 2.6rem;
      width: 2.6rem;
      border-radius: 50%;
      object-fit: cover;
    }

    .content {
      @extend .text-small;
      margin-left: 0.5rem;
      flex: 1;
      color: $primaryDarkColor;

      .nickname {
        @extend .text-medium;
        font-weight: bold;
      }

      .time {
        opacity: 29%;
      }
    }
  }

  > img {
    height: 3.2rem;
    width: 3.2rem;
    object-fit: cover;
    margin-left: 0.5rem;
  }

  > button {
    @extend .text-medium;
    color: $whiteColor;
    background-color: $primaryColor;
    padding: 0.25rem 0.75rem;
    border-radius: 0.25rem;
  }
}
