@use "../../../../styles/variables" as *;

.featured-products-container {

  .swiper-button-next::after {
    content: '⊳';
    letter-spacing: -0.2em;
  }

  .swiper-button-prev::after {
    content: '⊲';
    letter-spacing: -0.2em;
  }

  .swiper-button-next, .swiper-button-prev {
    transform: scaleY(1.4) scaleX(0.7);
    color: black;
    font-size: 2em;
  }
  .swiper-button-next {
    right: 4%;
  }
  .swiper-button-prev {
    left: 4%;
  }

  h1 {
    margin: 0.5em 0.5em 0 0.5em;
    color: $primaryColor;
  }
}
