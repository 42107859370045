@use "../../styles/variables" as *;
@import "../../styles/global";

.playIcon {
  transform: scale(2);
  position: absolute;
  z-index: 10;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.7));
  fill: rgba(255, 255, 255, 0.8);
  display: none;
}

.actionContainer {
  @extend .text-medium;
  font-weight: bold;
  color: $whiteColor;
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userInfo {
  display: flex;
  margin-top: 0.25rem;
  width: 100%;

  img {
    width: 2rem;
    height: 2rem;
    object-fit: cover;
    // aspect-ratio: 1/1;
    border-radius: 0.375em;
    flex-shrink: 0;
  }

  .userNames {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
    color: $whiteColor;
    word-break: break-all;
    width: -webkit-fill-available;
    overflow: hidden;

    div:first-child {
      @extend .text-medium;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 99%;
    }

    div:last-child {
      @extend .text-smaller;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 99%;
    }
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: $blackColor;
  height: 100%;
  width: 100%;

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
}

.videoThumbnail {
  width: 100%;
  object-fit: contain;
  display: block;
}

.infoContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 0.5rem 0.5rem 0.5rem;
  background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:first-child {
      align-items: flex-end;
    }

    &:nth-child(2) {
      align-items: center;
    }

    &:nth-child(3) {
      align-items: flex-end;
    }
  }
}

.hidden {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000093;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    @extend .text-medium;
    color: $whiteColor;
    margin-bottom: 0.5rem;
  }

  button {
    @extend .text-small;
    background-color: $primaryColor;
    color: $whiteColor;
    padding: 0.25rem 1rem;
    border-radius: 0.25rem;
  }
}
