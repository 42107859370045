@import "../../styles/variables";
@import "../../styles/global";

.component-alert-box {
  background-color: $whiteColor;
  min-width: 60%;
  max-width: 80%;
  border-radius: 1rem;
  height: auto;
  max-height: 95vh;
  overflow-y: scroll;

  &-title {
    @extend .text-medium;
    font-weight: bold;
    text-align: center;
    margin: 1.5em;
    white-space: pre-wrap;
  }

  .content {
    @extend .text-smaller;
    text-align: center;
    padding: 0 1.5rem 1.5rem 1.5rem;
    max-width: 300px;
    white-space: pre-wrap;
  }

  &-btn {
    background-color: $whiteColor;
    font-weight: bold;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top-width: 1px;
    border-top-color: $lightGrayColor;
    border-top-style: solid;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    color: $gray4Color;

    &.red {
      color: $primaryColor;
    }

    &:last-child {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
  }
}
