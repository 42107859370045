.plusIcon {
  height: 20px;
  width: 20px;
  object-fit: contain;
  cursor: pointer;
}

.popupVerification {
  width: 80%;
  transform: translateY(-1em);
  margin: 1em 0 0 0;
  p {
    margin: 0 0 0.5em 0;
  }

  p:first-child {
    font-size: 0.6875rem;
  }

  p {
    font-size: 0.625rem;
  }
}
