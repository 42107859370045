@import "../../../../styles/variables";
@import "../../../../styles/global";

.note {
  @extend .text-small;
  color: $gray4Color;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.terms {
  margin-top: 0.25rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  @extend .text-smaller;
  background-color: $lightGrayColor;
  border-radius: 0.5rem;

 p{
  height: 5.25rem;
  overflow: scroll;
  padding: 0;
  margin: 0;
 }
}

.submit {
  @extend .text-medium;
  background-color: $primaryColor;
  color: $whiteColor;
  padding: 0.5rem 3rem 0.5rem 3rem;
  border-radius: 0.25rem;

  &:disabled {
    opacity: 50%;
  }
}

.label {
  @extend .text-small;
  color: $blackColor !important;
}
