@import "../../styles/variables";

.dropShadow {
  filter: drop-shadow(0px 10px 10px #fc6767);
}

.container {
  filter: drop-shadow(0 -1rem 1rem #505c6229);
  background-color: $whiteColor;
  position: sticky;
  bottom: 0;
  width: 100vw;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  z-index: 100;
}
