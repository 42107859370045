@import "../../../../styles/global";
@import "../../../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin: 0 1rem 0.75rem 1rem;
}

.topContainer {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.25rem;
}

.time {
  @extend .text-smaller;
}

.avatar {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  object-fit: cover;
}

.message {
  @extend .text-medium;
  background-color: $primaryColor;
  color: $whiteColor;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  border-bottom-right-radius: 0rem;
  flex: 1;
  min-width: 0;
  overflow-wrap: break-word;
  margin-right: 0.5rem;
  white-space: pre-wrap;
}

.imgContainer {
  flex: 1;
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  img {
    max-width: 100%;
    max-height: 10rem;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  img:not(:first-child) {
    margin-top: 0.5rem;
  }

  img:first-child {
    border-top-right-radius: 1.5rem;
  }
}
