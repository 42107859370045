@import "../../styles/variables";

#spinner-container {
  background-color: rgba(224, 224, 224, 0.5);
  display: none;
  z-index: 99999;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;

  &.show {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #spinner {
    color: $primaryColor;
    width: 50px;
    height: 50px;
  }
}
