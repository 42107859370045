@use "../../../../styles/inputs";
@use "../../../../styles/variables" as *;

.header {
  position: sticky;
  background-color: white;
  z-index: 1;
  top: 0;
}

.top {
  height: 2.5em;
  display: flex;
  padding: 0.5em 1em 0 1em;
  background-color: white;

  input {
    @extend .discover-input;
    flex: 1;
    margin-right: 0.5rem;
  }

  button {
    @extend .text-medium;
    color: $primaryDarkColor;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      width: fit-content;
      margin: 0;
    }
  }
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    @extend .text-medium;
    color: $primaryDarkColor;
    width: 100%;
    border-bottom: 1px solid $lightGrayColor;
    padding: 0.75rem 0;

    &.active {
      font-weight: bold;
      border-bottom: 0.1em solid #3e3e3e !important;
    }
  }
}

.listContainer {
  height: 100%;
}

.freeWordItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid $lightGrayColor;

  .left {
    display: flex;
    align-items: center;
    flex: 1;
    margin-right: 0.5rem;
    min-width: 0;

    > div {
      @extend .text-medium;
      font-weight: bold;
      flex: 1;
      min-width: 0;
      overflow-wrap: break-word;
    }

    .nameContainer {
      min-width: 0;
      flex: 1;

      > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .avatar {
    width: 2.6rem;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 0.5rem;
  }

  .post {
    width: 3.5rem;
    aspect-ratio: 1;
    object-fit: cover;
    display: block;
  }
}
