@import "../../../../styles/global";
@import "../../../../styles/variables";

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid $lightGrayColor;

  div {
    @extend .text-medium;
    font-weight: bold;
    color: $primaryDarkColor;
    margin-right: 1rem;
  }

  img {
    width: 3.5rem;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
}
