@use "../../../../styles/variables" as *;
@import "../../../../styles/global";

.emailItem {
  padding: 1rem;
  border-bottom: 0.1em solid $lightGrayColor;

  div:first-of-type {
    @extend .text-medium;
    color: $gray4Color;
    font-weight: bold;
  }

  div:nth-of-type(2) {
    @extend .text-small;
    color: $gray4Color;
    margin-top: 0.5rem;
  }
}
