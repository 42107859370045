.container {
  display: flex;
  flex-direction: column;
}

.productGrid {
  display: grid;
  gap: 2px;
  grid-template-columns: repeat(2, 1fr);

  div {
    aspect-ratio: 4/5;
    display: flex;
    align-items: center;
    overflow: hidden;
    background: #fff;
    border: 1px solid #e0e0e0;

    img {
      width: 100%;
    }
  }
  
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vh;
  padding: 0 2em;
  text-align: center;
}
