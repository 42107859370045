@import "../../styles/global";
@import "../../styles/variables";

.reportContainer {
  color: $gray4Color;

  .title {
    @extend .text-large;
    font-weight: bold;
    border-bottom: 1px solid $lightGrayColor;
    padding: 0.5rem;
    text-align: center;
  }

  .content {
    padding: 1rem;
    @extend .text-medium;

    .intro {
      font-weight: bold;
      text-align: left;
    }

    .introText {
      text-align: left;
      margin-top: 0.5rem;
      margin-bottom: 0.75rem;
    }

    button {
      color: $gray4Color;
      padding: 0.75rem 0;
    }
  }
}
