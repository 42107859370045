.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 27vh;
  overflow-y: auto;
}

.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
