@import "../../../../styles/global";
@import "../../../../styles/variables";

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .avatar {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
    object-fit: cover;
  }

  .close {
    height: 8px;
    width: 8px;
    object-fit: contain;
  }

  .right {
    margin-left: 0.75rem;
    flex: 1;
    min-width: 0;

    > div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    > div:first-of-type {
      @extend .text-large;
      font-weight: bold;
    }

    > div:nth-of-type(2) {
      @extend .text-medium;
      margin-top: 0.25rem;
    }
  }
}
