@import "../../styles/global";
@import "../../styles/variables";

.container {
  text-align: center;
  padding: 1rem;

  .spinner {
    color: $primaryColor;
  }
}
