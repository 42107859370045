@import "../../../../styles/global";
@import "../../../../styles/variables";

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #eceff0;
}

.header {
  @extend .text-small;
  font-weight: bold;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $lightGrayColor;
  background-color: $whiteColor;
  padding: 0.5rem 1rem;
  z-index: 9999;

  > img {
    position: absolute;
    left: 1rem;
  }

  > div > img {
    margin-bottom: 0.15rem;
  }
}

.avatar {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  object-fit: cover;
}
