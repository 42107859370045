@use "../../../../styles/variables" as *;
@import "../../../../styles/global";

.container {
  height: 100%;
  overflow-x: hidden;
}

.mySwiper {
  width: 100%;
  height: 100%;

  img {
    display: block;
    width: 100%;
  }
}

.productDetail {
  margin: 0.5rem 1rem 0 1rem;
  color: $primaryDarkColor;

  .name {
    @extend .text-medium;
    font-weight: bold;
  }

  .price,
  .description {
    @extend .text-small;
    margin-top: 0.5rem;
  }

  .price {
    font-family: Open Sans;
  }
}

.goToShopBtn {
  width: 100%;
  padding: 0 1rem;
  margin-top: 1rem;

  button {
    width: 100%;
    @extend .text-medium;
    font-weight: bold;
    background-color: $primaryColor;
    color: $whiteColor;
    border-radius: 0.5em;
    padding: 0.5rem 0;
  }
}
