@use "../../../../styles/variables" as *;
@use "../../../../styles/global" as *;

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container {
  padding: 0.5rem 1rem;
  border-bottom: 0.1em solid $lightGrayColor;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;

  .title {
    @extend .text-large;
    font-weight: bold;
    color: $primaryDarkColor;
    margin-bottom: 0.5rem;
  }
}

.categoryContainer {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin: 0.5rem 0 0 0;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .category {
    @extend .text-medium;
    width: 100%;
    border: 1px solid $lightGrayColor;
    color: $gray4Color;
    padding: 0.25rem 0.75rem;
    margin-right: 0.2rem;
    border-radius: 0.25rem;
    text-align: center;

    &.active {
      color: $whiteColor !important;
      background-color: $primaryColor !important;
      border: none;
    }
  }
}
