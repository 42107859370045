@import "../../../../styles/variables";
@import "../../../../styles/global";

.btn {
  @extend .text-medium;
  color: $whiteColor;
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  border-radius: 0.25rem;
}

.red {
  background-color: $primaryColor;
  margin-bottom: 1rem;
}

.green {
  background-color: #40c107;
  margin-bottom: 1rem;
}

.blue {
  background-color: #1c9deb;
  margin-bottom: 1rem;
}

.gradient {
  background-image: linear-gradient(to left, #3689e0, #b534aa, #d93079, #ee8541, #fedd80);
}
