@import "../../../../styles/variables";
@import "../../../../styles/global";

.unblock {
  @extend .text-medium;
  color: $primaryDarkColor;
  border: 1px solid $lightGrayColor;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
}
