@import "../../../../styles/variables";
@import "../../../../styles/global";

.container {
  button {
    @extend .text-medium;
    background-color: $whiteColor;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $gray4Color;
    border: none;
    border-bottom: 1px solid $lightGrayColor;
    padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  }
}
