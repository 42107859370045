@import "../../../../styles/variables";
@import "../../../../styles/global";

.submit {
  color: $whiteColor;
  background-color: $primaryColor;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 0.25rem;
  margin-top: 5rem;

  &:disabled {
    opacity: 50%;
  }
}

.smallButton {
  @extend .text-smaller;
  color: $gray1Color;
  background-color: $whiteColor;
  margin-top: 1rem;
}
