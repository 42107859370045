@import "../../styles/variables";
@import "../../styles/global";

.container {
  margin-bottom: 1.5rem;

  .mainContainer {
    padding: 0.5rem 1rem 0rem 1rem;

    .likeCount {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;

      button {
        @extend .text-medium;
        font-weight: bold;
        color: $primaryDarkColor;
      }
    }

    .content {
      @extend .text-medium;
      color: $primaryDarkColor;
      margin-bottom: 0.25rem;
      overflow-wrap: break-word;

      .readMore {
        color: $gray4Color;
        opacity: 30%;
        margin-left: 0.5rem;
      }

      span {
        white-space: pre-wrap;
      }
    }

    .blurred {
      @extend .text-medium;
      color: $primaryDarkColor;
      opacity: 0.7;
    }

    .icon {
        margin-right: 0.5rem;

        &:last-child {
          margin-right: 0;
        }
    }
  }
}

.emptyContainer {
  width: 100%;
  aspect-ratio: 4/3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .checked {
    background-color: $primaryColor;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;

    img {
      width: 22px;
      height: 14px;
      object-fit: contain;
    }
  }

  .text {
    @extend .text-medium;
    margin: 1rem 0rem;
  }

  button {
    @extend .text-small;
    background-color: $primaryColor;
    color: $whiteColor;
    padding: 0.25rem 1.5rem;
    border-radius: 0.25rem;
  }
}
