@use "./global" as *;
@use "./variables" as *;

.discover-input {
  font-size: 1rem;
  width: 100%;
  border: none;
  background-color: $lightGrayColor;
  padding: 0.25em 0.25em 0.25em 2em;
  border-radius: 0.25em;
  background-image: url("../assets/images/ic_magnifying_glass.png");
  background-repeat: no-repeat;
  background-position: 0.5em 0.425em;
}
