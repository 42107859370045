@use '../../../../styles/variables' as *;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    font-size: .675rem;
    border-radius: 50%;
    color: white;
    background-color: $primaryColor;
    padding: .75rem .25rem;
    position: absolute;
    bottom: 10%;
  }

  p:last-child {
    letter-spacing: .1em;
  }

}

.title {
  transform: translateX(-1.5rem) !important;
}

.imageContainer {
  transform: translate(-1.5rem, 0.5rem) !important;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0 0 0 1em;
  }
}


@media all and (max-width: 320px) {
  .container {
    p:first-child {
      margin: 0 !important;
    }

    p:last-child {
      margin: 0 1em !important;
      font-size: .9rem;
    }
  }

  .imageContainer {
    transform: translate(-1.5rem, -7.5%) !important;
  }
}
