@import '../../styles/variables';
@import '../../styles/global';

.component-modal {
  background-color: rgba(0, 0, 0, 0.58);
  display: none;
  z-index: 999999;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  @-webkit-keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  &.show {
    display: flex;
    padding-bottom: 1rem;
  }

  &.bottom {
    align-items: flex-end;
  }

  &-container {
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
  }
}
