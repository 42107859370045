@import "../../../../styles/global";
@import "../../../../styles/variables";

.container {
  padding: 1.5rem;

  .input {
    margin-top: 0.5rem;

    input {
      @extend .text-medium;
    }
  }

  .note {
    color: $lightGrayColor;
    text-align: center;
    margin-top: 10rem;
  }
}

.info {
  @extend .text-small;
  background-color: $primaryColor;
  color: $whiteColor;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.share {
  @extend .text-small;
  background-color: $primaryColor;
  color: $whiteColor;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;

  &:disabled {
    opacity: 0.7;
  }
}

.error {
  color: red;
  text-align: center;
}
