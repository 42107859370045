@import "../../styles/variables";
@import "../../styles/global";

.component-text-input-field {
  .top-container {
    margin-bottom: 0.3rem;
  }

  .required {
    @extend .text-smaller;
    background-color: $primaryColor;
    color: $whiteColor;
    border-radius: 0.2rem;
    padding: 0.1rem 0.5rem 0.1rem 0.5rem;
  }

  .label {
    @extend .text-medium;
    color: $gray4Color;
  }

  .note {
    @extend .text-smaller;
    color: $gray4Color;
    margin-top: 0.3rem;
  }
}
