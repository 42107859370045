@use "../../../../styles/global" as *;
@use "../../../../styles/variables" as *;

.container {
  position: relative;
  max-height: 60vh;
}

.commentList {
  padding: 0 0.75em 5em 0.75em;
  height: 60vh;
  overflow-y: scroll;
}
