@import "../../../../styles/variables";

.fastCommentContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0.5em 0;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 9999;
  background-color: white;

  .avatar {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    object-fit: cover;
  }

  form {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    height: auto;
    padding: 0.25em;

    textarea {
      font-size: 0.8125rem;
      color: $primaryDarkColor;
      width: 100%;
      height: 2.5625rem;
      max-height: 5rem;
      line-height: 1.25rem;
      padding: 0.6rem 2.5rem 0.675rem 0.75rem;
      margin: 0 0 0 0.5em;
      border-radius: 0.5em;
      resize: none;
      border: none;
      outline: none;
      background-color: #e0e0e0;

      div {
        background-color: black;
      }
    }
  }
}

.sendBtn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 3.75%;
  height: 1.75em;
  width: 1.75em;
  border-radius: 50%;
  background-color: #d95454;

  &:disabled {
    opacity: 0.5;
  }
}
