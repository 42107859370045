@use "../../../../styles/variables" as *;
@use "../../../../styles/global" as *;
@use "../../../../styles/inputs" as *;

.container {
  .topContainer {
    position: sticky;
    top: 0;
    display: flex;
    padding: 0.5em 1em;
    background-color: white;
    z-index: 3;

    > div {
      flex: 1;
      margin-right: 0.5rem;
    }
  }

  & button:first-of-type {
    @extend .text-small;
    background-color: $whiteColor;
    color: $primaryColor;
    border: 0.1em solid $primaryColor;
    border-radius: 0.25rem;
    padding: 0 1rem;
  }

  input {
    @extend .discover-input;
  }
}

.mainContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;

  > div {
    aspect-ratio: 1/1;
    position: relative;
    cursor: pointer;

    > div:first-of-type {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 50%;
      background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 0.25rem;
      opacity: 80%;
    }

    > div:nth-of-type(2) {
      @extend .text-small;
      color: $whiteColor;
      font-weight: bold;
      position: absolute;
      bottom: 0.25rem;
      z-index: 2;
      width: 100%;
      text-align: center;
      padding: 0 0.5rem;

      div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }
  }
}
