.toast {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 95%;
  border-radius: 1em;
  height: 3.75em ;
  min-height: 3.75em;
  max-height: 3.75em;
  font-size: 0.75rem;
  padding: 0 0.5em;
}

@media all and (min-width: 321px) {
  .toast {
    font-size: 0.8125rem;
  }
}
