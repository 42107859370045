@import "../../styles/variables";
@import "../../styles/global";

.item {
  border-bottom: 1px solid $lightGrayColor;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;

  .left {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0;
    margin-right: 0.5rem;

    .nameContainer {
      min-width: 0;
      flex: 1;
    }
  }

  img {
    height: 2.6rem;
    width: 2.6rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 0.5rem;
  }

  .nickname {
    @extend .text-medium;
    font-weight: bold;
    color: $primaryDarkColor;
    margin-bottom: 0.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .username {
    @extend .text-medium;
    color: $primaryDarkColor;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
