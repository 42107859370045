.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2px;

  div {
    aspect-ratio: 4/5;
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #e0e0e0;
    overflow: hidden;

    img,
    video {
      width: 100%;
      display: block;
    }
  }
  
}
