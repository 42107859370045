@import "../../../../styles/global";
@import "../../../../styles/variables";

.mainContainer {
  @extend .text-medium;
  color: $gray4Color;

  > div {
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $lightGrayColor;
  }

  > textarea {
    padding: 1rem 1.5rem;
    color: $gray4Color;
    width: 100%;
  }
}
