* {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

body {
  font-family: Yu Gothic;
  // min-height: 100vh;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

button,
textarea {
  border: none;
  padding: 0;
  background-color: #fff;
}

body,
html {
  height: -webkit-fill-available;
  background-color: #fff;
}

#root {
  min-height: 100%;
}

.prevent-scroll {
  overflow: hidden !important;
}

.ptr {
  height: fit-content !important;
}

.ptr, .ptr__children {
  -webkit-overflow-scrolling: unset;
}

.swiper-slide .ptr {
  height: 100% !important;
}

.swiper-slide .ptr__children {
  display: block;
}