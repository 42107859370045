@import "../../../../styles/global";
@import "../../../../styles/variables";

.videoSlideTitle {
    font-weight: bold;
    margin-left: 12px;
}

.videoSlideContainer {
    background-color: #fff !important;
    margin-bottom: 46px;
    padding-left: 12px !important;
    overflow: hidden;
}

.videoItem {
    aspect-ratio: 9/16;
    width: 35vw !important;
}