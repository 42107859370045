@import "../../styles/variables";
@import "../../styles/global";

.container {
  padding: 15px;
  background-color: $whiteColor;
  z-index: 9999;
  min-height: calc(30px + 1.125rem * 1.5);

  &.bordered {
    border-bottom: 1px solid $lightGrayColor;
  }

  .left {
    left: 1rem;
  }

  .right {
    right: 1rem;
  }

  .title {
    @extend .text-large;
    font-weight: bold;
  }
}
