@import "../../styles/variables";
@import "../../styles/global";

.imgContainer {
  width: 100%;
  aspect-ratio: 4/5;
  background: #fff;
  display: flex;
  align-items: center;
  //overflow: hidden;
  position: relative;

  .img {
    width: 100%;
    object-fit: contain;
  }

  .imgNumber {
    @extend .text-smaller;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: #fff;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .userInfo {
    position: absolute;
    bottom: 0;
    color: $whiteColor;
    z-index: 1;
    min-width: 0;
    width: 100%;
    padding: 1rem;
    background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;

    .avatar {
      height: 2rem;
      width: 2rem;
      border-radius: 0.25rem;
      object-fit: cover;
      margin-right: 0.5rem;
    }

    > div {
      flex: 1;
      min-width: 0;

      > div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .name {
        @extend .text-small;
        font-weight: bold;
      }

      .username {
        @extend .text-smaller;
      }
    }
  }

  .iconContainer {
    top: 0.5rem;
    left: 0.5rem;
    opacity: 0; // khách điên

    div {
      background-color: rgba(0, 0, 0, 0.7);
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 0.75rem;

      &:first-child {
        margin-right: 0.25rem;
      }
    }

    .icon {
      height: 0.5rem;
      aspect-ratio: 1/1;
      object-fit: contain;
    }
  }
}

.bottomLink {
  @extend .text-medium;
  background-color: $primaryColor;
  color: $whiteColor;
  text-align: center;
  padding: 0.5rem 0;
  min-height: 37px;

  &::before {
    content: '広告';
    position: absolute;
    left: 0.5rem;
  }
}
