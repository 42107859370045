@import "../../../../styles/global";
@import "../../../../styles/variables";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $lightGrayColor;
  padding: 0.5rem 1rem;

  img {
    height: 2.75em;
    width: 2.75em;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }

  .left {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0;
    margin-right: 0.5rem;
  }
}

.activityContent {
  word-break: break-all;

  p {
    margin: 0 1em 0 0;
    font-size: 0.75rem;
  }

}

.name {
    font-weight: bold;
    font-size: 0.8125rem;
}

.avatar {
  margin: 0 0.5em 0 0;
  img {
    border-radius: 50%;
  }
}

.date {
  font-weight: normal;
  opacity: 0.29;
}
