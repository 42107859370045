@use "../../../../styles/inputs" as *;
@use "../../../../styles/variables" as *;
@use "../../../../styles/global" as *;

.container {
  // height: 100%;

  .topContainer {
    position: sticky;
    top: 0;
    display: flex;
    padding: 0.5em 1em;
    background-color: white;
    z-index: 1;

    > div {
      flex: 1;
      margin-right: 0.5rem;

      input {
        @extend .discover-input;
      }
    }

    button {
      @extend .text-small;
      background-color: $primaryColor;
      color: $whiteColor;
      padding: 0 1rem;
      border-radius: 0.25rem;
    }
  }
}

.postContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;

  > div {
    width: 100%;
    aspect-ratio: 4/5;
    position: relative;
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #e0e0e0;
    overflow: hidden;

    img,
    video {
      width: 100%;
      object-fit: contain;
      display: block;

      &.videoIcon {
        height: 17px;
        width: 17px;
        position: absolute;
        top: 5px;
        right: 5px;
        object-fit: contain;
      }
    }
  }
}
