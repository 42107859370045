@import "../../../../styles/variables";
@import "../../../../styles/global";

.smallButton {
  @extend .text-smaller;
  color: $gray1Color;
  background-color: $whiteColor;
  margin-top: 5rem;
  
}
