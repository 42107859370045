/**
  * Override SwiperJS's pagination, navigation styles
  */
.product-detail-images {

  .swiper-pagination,
  .swiper-pagination-fraction {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    width: 3.5em !important;
    height: 2em;
    top: 0;
    right: 0 !important;
    margin: 0.5em;
    border-radius: 1em;
    left: auto !important;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .swiper-pagination-current {
    position: relative;
  }

  .swiper-pagination-total {
    position: relative;
  }

  .swiper-wrapper {
    padding: 0;
    margin: 0;
    // height: calc(min(50vh, 270px));
    box-sizing: border-box;

    div {
      aspect-ratio: 4/5;
      overflow: hidden;
      display: flex;
      align-items: center;
      background: #fff;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: rgba(255, 255, 255, 0.7);
    height: 1.5em;
    width: 1.5em;
    border-radius: 50%;
    font-weight: bold;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.75rem;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0;
  }
}