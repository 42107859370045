@import "../../../../styles/global";
@import "../../../../styles/variables";

.container {
  display: flex;
  flex-direction: column;

  > button {
    @extend .text-medium;
    background-color: $primaryColor;
    color: $whiteColor;
    padding: 0.75rem;
    margin: 1.5rem;
    margin-top: 3rem;
    border-radius: 0.5rem;
  }
}

.header {
  @extend .text-medium;
  border-bottom: 1px solid $lightGrayColor;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  @extend .text-medium;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: $lightGrayColor;
  text-align: center;
  margin-top: 3rem;
}

.inputs {
  padding: 1rem;
}

.addBtn {
  @extend .text-medium;
  background-color: $primaryColor;
  color: $whiteColor;
  padding: 0.25rem 1rem;
  border-radius: 0.25rem;

  &:disabled {
    opacity: 70%;
  }
}

.error {
  color: red;
  text-align: center;
}
