.text-larger {
  font-size: 1.25rem;
}

.text-large {
  font-size: 1.125rem;
}

.text-medium {
  font-size: 0.875rem !important;
}

.text-small {
  font-size: 0.75rem !important;
}

.text-smaller {
  font-size: 0.625rem;
}

.text-tiny {
  font-size: 0.5rem;
}

.instagram {
  background-image: linear-gradient(to left, #3689e0, #b534aa, #d93079, #ee8541, #fedd80);
  color: #fff;
}

.twitter {
  background-color: #1c9deb;
  color: #fff;
}

.transparent-background {
  @extend .text-smaller;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000b3;
  color: #fff;
  z-index: 2;
}

.image-tag {
  @extend .transparent-background;
  padding: 0.4rem 0.75rem;
  border-radius: 0.25rem;
}

.followBtn {
  @extend .text-medium;
  background-color: #d95454;
  color: #fff;
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
}

.followingBtn {
  @extend .text-medium;
  background-color: #fff;
  color: #d95454;
  border: 1px solid #d95454;
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
}

// larger 20 - 1.25
// large 18 - 1.125
// medium 14 - 0.875
// small 12 - 0.75
// smaller 10 - 0.625
// tiny 8 - 0.5
