@import "../../styles/global";
@import "../../styles/variables";

.container {
  position: absolute;
  z-index: 100;
  border: 1px solid $lightGrayColor;
  left: -50px;
  right: -50px;
  background-color: $whiteColor;
  overflow: hidden;
  border-radius: 0.5rem;

  > div {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  > div:first-of-type {
    border-bottom: 1px solid $lightGrayColor;
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;

    img {
      height: 16px;
      width: 20px;
      object-fit: contain;
    }
  }

  > div:nth-of-type(2) {
    padding-top: 0.25rem;
    padding-bottom: 0.5rem;

    img {
      height: 20px;
      width: 20px;
      object-fit: contain;
    }
  }
}
