.clipBackground {
  height: 100vh;
  background-color: #d95454;
  clip-path: polygon(75% 0, 100% 0, 100% 100%, 75% 100%, 97% 50%);
  z-index: 9999;
}

.main {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 12%;
  clip-path: polygon(0 0, 80.5% 0, 97% 38%, 70% 100%, 0 100%);
}

.welcomeText {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: PlayfairDisplay;
  transform: translateX(-1.5rem);
  h2 {
    font-size: 1.25rem;
    font-weight: bold;
  }
  h1 {
    font-size: 2.6875rem;
    font-weight: bold;
  }
}

.content {
  height: 70%;
  width: 100%;
  z-index: 10;
}

@media all and (max-width: 320px) {
  .main {
    img {
      transform: scale(.8);
    }
  }

  .welcomeText {
    h1 {
      font-size: 2.25rem;
    }
    h2 {
      font-size: 1rem;
    }
  }

}
