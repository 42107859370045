@use '../../../../styles/variables' as *;

.guidelines-swiper-container {
  span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: $primaryColor !important;
  }
  .swiper-slide {
    background-color: white !important;
    display: block;
  }
  .swiper {
    background-color: white !important;
  }
}


