@import "../../styles/global";
@import "../../styles/variables";

.reportSuccessContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  margin-top: 0.5rem;

  div:first-of-type {
    background-color: $primaryColor;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div:nth-of-type(2) {
    @extend .text-larger;
    font-weight: bold;
    margin: 1rem 0;
  }

  div:nth-of-type(3) {
    @extend .text-small;
    margin-bottom: 1rem;
  }

  button {
    @extend .text-medium;
    background-color: $primaryColor;
    color: $whiteColor;
    width: 100%;
    padding: 0.5rem 0;
    border-radius: 0.5rem;
  }
}

.content {
  text-align: center;

   p {
     margin: 0;
     padding: 0;
   }
}
