@import "../../../../styles/global";
@import "../../../../styles/variables";

.videoHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 25;
  padding: 15px;

  > img {
    position: absolute;
    left: 15px;
  }

  > div {
    @extend .text-large;
    color: $whiteColor;
    font-weight: bold;
  }
}

.mobile {
  display: flex;
  flex-direction: column;
  margin: 0 0 2em 0;
  a {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;

    img {
      width: 50%;
      height: auto;
    }
    
  }
}
