@use "../../../../styles/variables"as *;

.product {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 90%;
  margin: 0.5em auto 1em auto;
  padding: 0.5em;
  border: 2px solid $lightGrayColor;
  border-radius: 2%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  div {
    margin: 0.5em 0;
    aspect-ratio: 4/5;
    display: flex;
    align-items: center;
    border-radius: 0.25em;
    // border: 1px solid #e0e0e0;
    background: #fff;
    overflow: hidden;

    img {
      width: 100%;
      align-self: center;
    }
  }

  p {
    padding: 0;
    margin: 0;
    font-size: 0.75em;
  }

  p:first-child {
    font-weight: bold;
  }

  a {
    text-decoration: none;
  }
}