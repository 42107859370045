@import "../../styles/global";
@import "../../styles/variables";

.container {
  > div {
    @extend .text-small;
    border-bottom: 1px solid $lightGrayColor;
    padding: 0.75rem 1.5rem;
    color: $gray4Color;

    .title {
      @extend .text-medium;
      color: $primaryDarkColor;
      font-weight: bold;
    }

    .switchContent {
      margin-right: 1rem;
    }

    > button {
      @extend .text-small;
      padding: 0.25rem 0.75rem;
      border-radius: 0.25rem;
    }
  }
}
