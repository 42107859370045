@import "../../../../styles/global";
@import "../../../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 80%;
  margin-bottom: 0.75rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.topContainer {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.25rem;
  max-width: 100%;

  .avatar {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 0.5rem;
  }

  .message {
    @extend .text-medium;
    background-color: $whiteColor;
    padding: 0.5rem 1rem;
    border-radius: 1.5rem;
    border-bottom-left-radius: 0rem;
    flex: 1;
    min-width: 0;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }
}

.time {
  @extend .text-smaller;
  align-self: flex-end;
}

.imgContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  img {
    max-height: 10rem;
    max-width: 100%;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    width: auto;
    height: auto;
  }

  img:not(:first-child) {
    margin-top: 0.5rem;
  }

  img:first-child {
    border-top-left-radius: 1.5rem;
  }
}
