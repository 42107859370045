.swiper {
  background-color: #000;
}

.swiper-wrapper {
  display: flex;
  align-items: center;
}

.swiper-slide {
  width: 80%;
  background-color: #fff;
}

.swiper-slide:nth-child(2n) {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #d95454;
  background-color: #000;

  button {
    background-color: #d95454;

    color: #fff;
    font-weight: bold;
    font-size: 1rem;
  }
}
