@import "../../styles/variables";
@import "../../styles/global";

$border-radius: 1rem;

.component-action-sheet {
  width: 80%;
  max-width: 30rem;

  button {
    background-color: $whiteColor;
    border: none;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    color: $gray4Color;

    &.red {
      color: $redColor;
    }
  }

  > button {
    border-radius: $border-radius;
  }

  .top-container {
    background-color: $whiteColor;
    border-radius: $border-radius;
    margin-bottom: 0.4rem;

    button:not(:last-child) {
      border-bottom: 1px solid $lightGrayColor;
    }

    button:first-child {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    button:last-child {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}
